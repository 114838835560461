@import "style/variables";

.tetris{
  width: 100vw !important;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $charcoal;
  overflow: hidden;
}

.content{
  position: relative;
  z-index: 2;
  max-width: 46.5rem;
  margin: 0 1.5rem;
  text-align: center;
  text-shadow: $charcoal 0.0625rem 0 0.625rem;

  .title{
    font-size: 2.25rem;
    font-weight: 900;

    @include breakpoint(sm){
      font-size: 2.8125rem;
    }
    @include breakpoint(md){
      font-size: 3.5625rem;
    }
  }

  .buttonWrapper{
    padding-top: 2.25rem;
    display: flex;
    gap: 2.25rem;
  }
}

.tetrisTiles{
  position: absolute;
  z-index: 0;
  scale: 0.75;
  @include breakpoint(md){
    scale: 1;
  }

  &:nth-of-type(1){
    left: 0;
    transform: translate(4.5rem, -9rem);
  }

  &:nth-of-type(2){
    left: 0;
    transform: translateX(-9rem);

    @include breakpoint(sm){
      transform: translateX(0);
    }
  }

  &:nth-of-type(3){
    bottom: 0;
    right: 0;
    transform: translate(9rem, -9rem);
    @include breakpoint(sm){
      transform: translate(0, -9rem);
    }
  }

  &:nth-of-type(4){
    bottom: 0;
    right: 0;
    transform: translateX(9rem);
    @include breakpoint(sm){
      transform: translateX(0);
    }
  }
}



