@use "sass:string";
@import "style/variables";
$bg-glow-height: 20.0rem;

.landingPage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $muiSurface1;
  padding: 0 10vw;
  font-family: Metropolis, sans-serif;

  > div {
    width: 80vw;
    position: relative;
    z-index: 1;
  }

  .sectionDark::after{
    content: "";
    position:absolute;
    display: block;
    left:-50%;
    top:0;
    width: 200vw;
    height: 100%;
    background-color: $charcoal;
    z-index: -1;
  }
}

.spacer{
  height: 7.5rem;
  background: transparent;
}
